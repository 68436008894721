.content-table {
    font-size: 0.9rem !important;
}
.jJhCqB div:first-child {
    white-space: pre-line !important;
    vertical-align: middle;
    padding-top: 20px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ofEne {
    font-size: 0.9rem !important;
    overflow: visible;
    white-space: pre-line;
    text-overflow: ellipsis;
    font-weight: bolder;
    padding: 20px 0 20px 0;
}