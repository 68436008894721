#ultimas_noticias .img-carousel{
    position: relative;
}
#ultimas_noticias .title_img_carousel {
    position: absolute;
    width: 100%;
    height: 55px;
    background: linear-gradient(to right, var(--wine), var(--pink));
    padding: 10px 15px;
}
#ultimas_noticias .title_img_carousel h6{
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    margin: 0;
}
#ultimas_noticias .title_img_carousel p{
    color: var(--whiteBackground);
    font-size: 13px;
}
.video-content {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
}