.inicio_item a{
  font-size: 1.1rem;
  text-decoration: none;
}
.image-x{
  background-image: url(../../../public/assets/imgs/micrositios.png);
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
}
.custom-4 a::before {
  background-image: url(../../../public/assets/imgs/circle-arrow.svg);
  height: 20px;
  width: 20px;
  content: "";
  position: absolute;
  left: 1px;
  top: 10px;
  z-index: 999
}
.custom-4 a {
  padding-left: .5rem;
}
.custom-4 a:hover::before {
  background-image: url(../../../public/assets/imgs/circle-arrow-active.svg);
  height: 20px;
  width: 20px;
  content: "";
  position: absolute;
  left: 1px;
  top: 10px;
  z-index: 999
}
.bd-highlight2 {
  /* margin: 0 0 28px; */
  background-color: white;
  border-radius: 4px;
  border: solid 1px #dee2e6;
  overflow: hidden;
  margin: none !important
}
@media (max-width: 576px) {
  .bd-highlight2 {
      margin: auto 0 3em;
      padding: 0 0;
  }
  /* #r-w {
    width: 334px !important
  } */
}
.list-font {
  font-family: 'Montserrat-Bold';
  font-size: large;
}