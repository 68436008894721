.modal-contenido {
    background: #3d3d3d;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 0px;
    border-radius: 8px;
    max-width: 100%;
    max-height: 90%; /* Cambié a 90% para que se vea bien en dispositivos pequeños */
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (max-width: 768px) {
    .modal-contenido {
        max-width: 95%;
        max-height: 95%;
        padding: 10px;
    }
}
.hr-sec{
    margin: 10px 0 10px;
    background-color: var(--gray);
    border-top: 1px solid var(--gray);
    opacity: 1;
    margin-bottom: 30px;
}
.hr-sec::before {
    content: " ";
    width: 35px;
    height: 3px;
    background-color: var(--gray) !important;
    display: block;
    position: absolute;
}