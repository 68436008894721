.transparencia_title{
    text-align: center;
    padding-bottom: 20px;
}
.transparencia_title h1{
    color: var(--wine);
    font-family: Montserrat-Bold;
}
.transparencia-container{
    padding-top: 50px;
    margin-bottom: 50px;
}
.transparencia_item a{
    color: var(--gold);
    font-size: 20px;
    text-decoration: none;
    display: flex;
}
@media (max-width: 576px){
    .transparencia-container{
        min-height: 600px;
    }
    .transparencia_item a{
        padding: 10px 0;
    }
}
@media (min-width:577px) and (max-width:820px){
    .transparencia-container{
        min-height: 600px;
    }
}
.css-02{
    /*display: flex;
    margin-top: 2em;
    width: 100%;*/
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: calc(400px - 5em)
  }
.css-02a{
    background-color: rgb(255, 255, 255);
    color: rgb(74, 73, 67);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    overflow: hidden;
    width: 280px;
    height: 274px;
    transition: margin 0.2s ease 0s;
}
.css-02b{
    font-size: 4rem;
    width: 100%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
  }
.css-02text{
    margin: 27px 0px 0px;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    font-family: "Montserrat";
    text-align: center;
    font-size: 20px;
}
.btn-outline-primary{
    border: none;
    background-color: white;
    color: var(--wine);
    font-size: 1.5rem;
}
.btn-outline-primary:hover {
    background-color: var(--wine);
    border: none;
}
.btn-outline-primary {
    --bs-btn-color: var(--pink);
    --bs-btn-border-color: var(--pink);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--pink);
    --bs-btn-hover-border-color: var(--pink);
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--pink);
    --bs-btn-active-border-color: var(--pink);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--pink);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--pink);
    --bs-gradient: none;
}
.css-02a1-0{
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    color: inherit;
    display: block;
    text-align: inherit;
    width: 100%;
    height: 274px;
}
@media (max-width: 767px) { 
    .css-1f{
        margin-top: 1em;
        margin-right: 0px;
        margin-left: 0px;
    }
}
.css-text-2 {
    font-size: 0.8rem;
    padding: 20px 20px
}