.embed-responsive{
    position:relative;
    display:block;
    height:0;
    padding:0;
    overflow:hidden
}
.mi-iframe
{
    margin-top: 30px;
}