/* frames */

.embed-container {
    margin-top: 26px;
    position: relative;
    padding-bottom: 25%;
    height: 600px !important;
    overflow: hidden;
    margin-right: 20px
    /*border: 2px solid #eee;
    box-shadow: 1px 1px 10px rgba(192,192,192,0.3);*/
}

.embed-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
}

.embed-responsive{
    position:relative;
    display:block;
    height:0;
    padding:0;
    overflow:hidden
}

.embed-responsive .embed-responsive-item,.embed-responsive iframe,.embed-responsive embed,.embed-responsive object,.embed-responsive video{
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    height:100%;
    width:100%;
    border:0
}

.embed-responsive.embed-responsive-16by9{
    padding-bottom:56.25%
}

.embed-responsive.embed-responsive-4by3{
    padding-bottom:75%
}

.mi-iframe
{
    margin-top: 30px;
}
.ratio-h{
    min-height: calc(100vh + 20rem) !important;
}
/* @media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }
}
@media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px;
    }
}
@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 720px;
    }
}
@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px;
    }
} */