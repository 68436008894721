#banner-principal{
    padding-top: 30px;
}
#banner-principal .img-carousel{
    width: 100%;
    height: 500px;
}
#banner-principal .search {
    position: absolute;
    bottom: 55px;
    margin-left: 50%;
    transform: translateX(-50%);
}
#banner-principal .search input {
    min-width: 500px;
    text-align: center;
    border: 0px;
    padding: 10px 30px;
    font-size: 12px;
    box-shadow: 0 3px 3px #757575;
    border-radius: 4px;
}
#banner-principal .search input:focus,
#banner-principal .search input:active{
    outline: none;
}
#banner-principal .search .icon-search {
    position: absolute;
    margin-top: 12px;
    margin-left: 12px;
}
@media (max-width: 576px){
    #banner-principal .search input {
        min-width: 350px;
    }
}
