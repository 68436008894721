.card-documents{
	border: 1.5px solid var(--gold);
	border-radius: 20px;
	margin-bottom: 1.5rem;
	margin-right: 1rem;
	margin-left: 0.7rem;
}
.card-documents h5{
	color: var(--pink);
	margin-bottom: 10px;
	font-family: "Montserrat-SemiBold";
}

.card-documents div div p a{
	padding-right: 0.3rem;
}
.subtitulo-armonizacion h5{
	padding-top: 1.5rem !important;
}
.anual h5{
	padding-top: 4rem !important;
}
.px-1_5{
	padding-left: 0.4rem !important;
	padding-right: 0.4rem !important;
}
.bg-dangerous-text{
	color: crimson !important;
}