.card_anio h6{
    color: var(--wine);
}
.card_trimestre .bg-success, .bg-danger{
    float: right;
}
.card_trimestre p{
    color: var(--gold);
}
.card_trimestre .bg-success a{
    color: #fff;
    text-decoration: none;
}
.card_trimestre .bg-danger a{
    color: #fff;
    text-decoration: none;
}
@media (max-width:576px) {
    .card_trimestre p{
        font-size: 17px;
    }
    .card_anio h5, .card_anio h6{
        font-size: 25px;
        font-family: Montserrat-Bold;
    }
}
.badge{
    height: min-content;
}