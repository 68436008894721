.card-documents{
	border: 1px solid var(--gold);
	border-radius: 20px;
	margin-bottom: 1.5rem;
	margin-left: 1.5rem;
}
.card-documents h5{
	color: var(--pink);
	margin-bottom: 10px;
	font-family: "Montserrat-SemiBold";
}
.card-documents div div p a{
	padding-right: 0.3rem;
}
.card-item{
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	border-top: 1px solid var(--gray);
}
.bg-dangerous{
    background-color: crimson !important;
}