:root{
    --wine-25: rgba(27, 65, 105, 0.15) !important;
}
a:hover{
    text-decoration: underline;
    transition: all 0.3s;
}
.btn-toggle button {
    display: block;
    padding: .25rem .9rem;
    color: #212529 !important;
    background-color: transparent;
    border: 0;
}
ul .btn-toggle{
    width: -webkit-fill-available;
}
.bd-highlight {
    /* margin: 0 0 28px; */
    background-color: white;
    border-radius: 4px;
    border: solid 1px #dee2e6;
    overflow: hidden;
}
.activeMenu {
    color: var(--wine) !important;
    background-color: var(--wine-25) !important;
    border: 0 transparent;
    border-right: 3px solid var(--pink);
    text-decoration: underline;
    text-decoration-color: var(--wine);
    font-family: "Montserrat-SemiBold";
    width: -webkit-fill-available;
}
.activeMenu button {
    color: var(--wine) !important;
    text-decoration: underline;
    text-decoration-color: var(--wine);
}
.btn-toggle:hover,
.btn-toggle:focus{
    color: var(--wine) !important;
    background-color: var(--wine-25);
    border: 0 transparent;
    border-right: 3px solid var(--wine);
    text-decoration: underline;
    text-decoration-color: var(--wine);
}
.armonizacion_item a{
    color: var(--wine);
    font-size: 1.3rem;
    text-decoration: none;
    display: flex;
}
.accordion-button{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.1rem 0 0.1rem 1.3rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}
.accordion-button:not(.collapsed) {
    color: var(--wine);
    background-color: #e7f1ff;
    box-shadow: inset 0 0px 0 rgba(0,0,0,.125);
    border: none !important;
    padding: 0 0 0 1.4rem;
}
.accordion-button::after {
    content: "";
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    margin-right: 1rem;
    background-image: url(../../../public/assets/imgs/chevron-off.svg);
    background-repeat: no-repeat;
    background-size: 1rem;
    transition: transform .1s ease-in-out;
}
.accordion-button:not(.collapsed)::after {
    background-image: url(../../../public/assets/imgs/chevron.svg) !important;
    transform: rotate(-180deg);
}
.accordion-collapse li::before {
    padding-bottom: 1rem;
    text-decoration: underline;
}
.armonizacion-button{
    padding: 0.1rem 0 0.1rem 1.3rem;
}
.accordion-custom{
    background-color: none !important;
    border: none !important;
    padding: 0 0 !important;
}
.subMenus{
    cursor: pointer;
}
.subMenus3n{
    background-color: lime !important;
}
.text-custom a{
    text-decoration: none !important;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 150px;
    z-index: 0;
    overflow-y: auto;
}
}
@media (max-width: 576px) {
    .offcanvas-body {
        flex-grow: 1;
        padding: 0;
        overflow-y: auto;
    }
}
/*@media (max-width: 576px) {
    #r-w {
      width: 360px !important;
    }
  }*/