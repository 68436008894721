.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #3d3d3d;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 0px;
  border-radius: 8px;
  max-width: 100%;
  max-height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .modal-content {
    max-width: 100%;
    max-height: auto;
  }
}

.modal-content picture img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.modal-content .bottomClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;
}

.button_slide {
  margin: 5px;
  width: auto;
  color: #FFF;
  border: 2px solid #f0f0f0;
  background-color: #691B32;
  border-radius: 50px;
  padding: 5px 20px;
  display: inline-block;
  font-family: "Montserrat-SemiBold";
  font-size: 12px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #BC955B;
  -webkit-transition: ease-out 0.6s;
  -moz-transition: ease-out 0.6s;
  transition: ease-out 0.6s;
}

@media (max-width: 768px) {
  .button_slide {
    top: 83%;
  }
}

.slide_right:hover {
  box-shadow: inset 300px 0 0 0 #BC955B;
}

.slide_left:hover {
  box-shadow: inset 0 0 0 50px #BC955B;
}